import React, { useEffect } from 'react';

import PostIt from 'components/PostIt';
import { Box, Title, Input } from './Section.style';

const sortedBy = (limits, votesByHash) => (b, a) => {
  if (limits.sortBy === 'points') {
    return [].concat(votesByHash[a.hash]).length - [].concat(votesByHash[b.hash]).length;
  }
  return 0;
};

// Section.component
const Section = (props) => {
  const { posts, section, emoji, title, extraProps } = props;
  const { limits, votesByHash, setItems, userName, itemsByHash, theme } = extraProps;

  useEffect(() => {
    const sec = document.getElementById(`section-${section}`);
    const className = sec.className
      .split(' ')
      .filter((name) => !['animate__animated', 'animate__hinge'].includes(name))
      .join(' ');
    const id = setTimeout(() => {
      if (limits.state === 2 && section < 3) {
        sec.className = `${className} animate__animated animate__hinge`;
      } else {
        sec.className = `${className} animate__animated`;
      }
    }, 1600);
    return () => clearTimeout(id);
  }, [limits.state, section]);

  return (
    <Box id={`section-${section}`} section={section} theme={theme}>
      <Title>
        <span role="img" aria-label="rainbow">
          {emoji}
        </span>
        &nbsp;
        {title}
      </Title>
      {limits.state === 0 && (
        <Input
          placeholder="Press enter to save"
          onKeyUp={handleAdd({ section, setItems, userName, itemsByHash })}
          className="form-control"
          theme={theme}
        />
      )}
      <div style={{ flex: 1, overflow: 'auto' }}>
        {posts
          .filter((i) => i.section === section || (section === 5 && i.section > 2))
          .sort(sortedBy(limits, votesByHash))
          .map((p) => (
            <PostIt key={p.hash} {...p} {...extraProps} />
          ))}
      </div>
    </Box>
  );
};

function handleAdd({ section, setItems, userName, itemsByHash }) {
  return (e) => {
    if (e.keyCode !== 13 || e.target.value.trim() === '') return;

    const invalidChars = ['.', '#', '$', '/', '[', ']'];
    const cleanVal = e.target.value
      .trim()
      .split('')
      .reduce((_clean, char) => {
        return _clean + (invalidChars.indexOf(char) !== -1 ? '' : char);
      }, '');
    const hash = userName + cleanVal + `${section}`;
    setItems({
      ...itemsByHash,
      [hash]: {
        author: userName,
        description: e.target.value,
        hidden: true,
        section,
        hash,
      },
    });
    e.target.value = '';
  };
}

export default Section;

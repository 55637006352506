import React, { useEffect } from 'react';
import swal from 'sweetalert2';

import { getRandomColor } from 'services/color';
import logo from './whitelogo.png';

const Item = ({ children, onClick, style }) => (
  <li className="nav-item active">
    <span className="nav-link">
      <button onClick={onClick} style={style} className="btn btn-info btn-sm">
        {children}
      </button>
    </span>
  </li>
);
const MenuIcon = () => (
  <button
    className="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarCollapse"
    aria-controls="navbarCollapse"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span className="navbar-toggler-icon"></span>
  </button>
);

export default (props = {}) => {
  const {
    userName,
    limits,
    mytotal,
    userSettingsByName,
    setUserSetting,
    setIsVotingTime,
    setAreVotesVisible,
    setSortBy,
    // setAllowLogin,
    theme,
    resetVotes,
    resetItems,
    resetUsers,
    setSprint,
    setTheme,
    setAppState,
    setUserToOpen,
    // setUrl,
  } = props;
  const votesLeft = (limits.votes || 0) - mytotal;
  const handleColorChange = () => {
    setUserSetting(userName, {
      ...userSettingsByName[userName],
      online: true,
      ...getRandomColor(),
    });
  };
  // const handleUrl = () => {
  //   const url = prompt('What is the url', limits.url);
  //   if (!!url) setUrl(url);
  // };
  // const handleOpenTo = () => {
  //   const username = prompt('What is the username', limits.userToOpen);
  //   if (!!username) setUserToOpen(username);
  // };

  const setEverythingForState = (state, nextState) => {
    switch (state) {
      case 0: // write
        setSortBy('-');
        setAreVotesVisible(false);
        setIsVotingTime(false);
        setAppState(nextState);
        break;
      case 1: // remove
        setSortBy('-');
        setAreVotesVisible(false);
        setIsVotingTime(false);
        setAppState(nextState);
        break;
      case 2: // vote
        setSortBy('-');
        setAreVotesVisible(false);
        setIsVotingTime(true);
        setAppState(nextState);
        break;
      case 3: // show votes
        setSortBy('points');
        setAreVotesVisible(true);
        setAppState(nextState);
        // setIsVotingTime(false);
        break;
      case 4: // reset
        swal
          .fire({
            icon: 'warning',
            title: 'Are you sure you want to reset everything? (retro is over?)',
            confirmButtonText: `RESET NOW`,
            confirmButtonColor: 'brown',
          })
          .then(({ isConfirmed }) => {
            if (isConfirmed) {
              setSortBy('-');
              setAreVotesVisible(false);
              setIsVotingTime(false);
              setAppState(0);
              resetVotes();
              resetItems();
              resetUsers();
              setSprint(limits.sprint + 1);
              setAppState(nextState);
              setTimeout(() => {
                document.location.href = '/';
              });
            }
          });
        break;
      default:
    }
  };
  const handleChangeState = () => {
    setEverythingForState(limits.state + 1, limits.state + 1 === 4 ? 0 : limits.state + 1);
  };
  const handleGoBack = () => {
    if (limits.state - 1 < 0) return;
    setEverythingForState(limits.state - 1, limits.state - 1);
  };

  const handleTheme = () => {
    setTheme(userName, theme === 'dark' ? 'light' : 'dark');
  };

  useEffect(() => {
    swal
      .fire({
        icon: 'success',
        title: `Time to: ${limits.states[limits.state]}`,
        timer: 1200,
        showConfirmButton: false,
      })
      .then((r) => {
        if (limits.state === 2) {
          const divs = Array.from(document.getElementsByClassName('animate__animated_section'));
          divs.forEach((div) => {
            div.className = 'animate__animated animate__hinge';
          });
        }
      });
  }, [limits.state, limits.states]);

  useEffect(() => {
    if (limits.userToOpen === userName) {
      window.open(limits.url);
      setUserToOpen('');
    }
  });

  useEffect(() => {
    if (!userSettingsByName[userName].theme) {
      swal
        .fire({
          icon: 'info',
          title: 'Dark theme available',
          position: 'top',
          confirmButtonText: `Let's try it out!`,
          confirmButtonColor: '#002F4D',
        })
        .then(() => {
          setTheme(userName, 'dark');
        });
    }
    //eslint-disable-next-line
  }, []);

  const currentState = limits.states[limits.state];
  const nextState = limits.states[limits.state + 1] || limits.states[0];

  const today = new Date();
  const daysLeft = 25 - today.getDate();

  useEffect(() => {
    if (theme === 'dark') {
      document.body.style.background = '#121212';
      document.body.style.color = 'white';
    } else {
      document.body.style.background = 'rgb(245, 247, 248)';
      document.body.style.color = '#000';
    }
  }, [theme]);

  const spanStyle = {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  };

  return (
    <div
      style={{ backgroundColor: theme === 'dark' ? '#121212' : '#002F4D' }}
      className="navbar navbar-expand-md navbar-dark fixed-top"
    >
      <span className="navbar-brand">CS Retro: s{limits.sprint}</span>
      <MenuIcon />
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <ul className="navbar-nav mr-auto">
          {daysLeft > 0 && (
            <li className="nav-item active">
              <span className="nav-link" style={spanStyle}>
                X-Mas: {daysLeft} days left
              </span>
            </li>
          )}
          <li className="nav-item active">
            <span className="nav-link" style={spanStyle}>
              Hello, {userName}
            </span>
          </li>
          <li className="nav-item active">
            <span className="nav-link" style={spanStyle}>
              Votes: {votesLeft}
            </span>
          </li>
          <Item
            style={{ background: theme === 'dark' ? '#000' : '#fff', color: theme === 'dark' ? '#fff' : '#000' }}
            onClick={handleTheme}
          >
            {theme || 'light'}
          </Item>
          <Item
            style={{
              background: userSettingsByName[userName].background,
              color: userSettingsByName[userName].color,
            }}
            onClick={handleColorChange}
          >
            My Color
          </Item>
          <li className="nav-item active">
            <span className="nav-link" style={spanStyle}>
              Time to: {currentState}
            </span>
          </li>
          {limits.admin === userName && (
            <>
              <Item onClick={handleChangeState}>next: {nextState}</Item>
              {limits.state !== 0 && <Item onClick={handleGoBack}>go back!</Item>}
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

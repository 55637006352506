import { connect } from 'react-firebase';

import Lobby from './Lobby.component';

const mapFirestoreToProps = ({ project = 'ffyn' }, ref) => ({
  limits: `${project}/limits`,
  //-
  userSettingsByName: `${project}/userSettings`,
  setUserSettings: (data) => ref(`${project}/userSettings`).set(data),
});

export default connect(mapFirestoreToProps)(Lobby);

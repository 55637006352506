import styled from 'styled-components';

export const StyledPostIt = styled.div`
  border-left: 10px solid transparent;
  border-bottom: 1px solid ${({ theme }) => (theme === 'dark' ? '#444' : '#ccc')};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

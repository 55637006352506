import { connect } from 'react-firebase';
import Navbar from './Navbar.component';

const mapFirestoreToProps = ({ project = 'ffyn' }, ref) => ({
  setIsVotingTime: (data) => ref(`${project}/limits/isVotingTime`).set(data),
  setAreVotesVisible: (data) => ref(`${project}/limits/areVotesVisible`).set(data),
  setSortBy: (data) => ref(`${project}/limits/sortBy`).set(data),
  setAllowLogin: (data) => ref(`${project}/limits/allowLogin`).set(data),
  setUserSetting: (username, data) => ref(`${project}/userSettings/${username}`).set(data),
  setUserToOpen: (data) => ref(`${project}/limits/userToOpen`).set(data),
  setUrl: (data) => ref(`${project}/limits/url`).set(data),
  setAppState: (data) => ref(`${project}/limits/state`).set(data),
  setTheme: (username, data) => ref(`${project}/userSettings/${username}/theme`).set(data),
  resetVotes: () => ref(`${project}/votes`).set(null),
  resetItems: () => ref(`${project}/items`).set(null),
  resetUsers: () => ref(`${project}/userSettings`).set(null),
  setSprint: (data) => ref(`${project}/limits/sprint`).set(data),
});

export default connect(mapFirestoreToProps)(Navbar);

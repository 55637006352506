import styled from 'styled-components';

import { navbarHeight } from 'components/Board/Board.style';

export const Box = styled.div`
  height: ${({ section }) => (section === 5 ? `calc(100vh - ${navbarHeight}px)` : `calc(50vh - ${navbarHeight}px)`)};
  width: ${({ section }) => (section === 5 ? '100vw' : '50vw')};
  border: 1px solid ${({ theme }) => (theme === 'dark' ? '#444' : 'black')};
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div`
  display: flex;
`;
export const Title = styled.div`
  background: #000;
  color: white;
  text-align: center;
  font-weight: bold;
`;
export const Input = styled.input`
  width: 100%;
  margin-bottom: 4px;
  background: ${({ theme }) => (theme === 'dark' ? '#121212' : '#fff')};
  color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#121212')};
  border-color: ${({ theme }) => (theme === 'dark' ? '#444' : '#121212')};
`;

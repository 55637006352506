import React from 'react';

import Navbar from 'components/Navbar';
import Section from 'components/Section';
import Footer from 'components/Footer';
import { Container, navbarHeight, Row } from './Board.style';

// Board.component
export default (props) => {
  const {
    itemsByHash,
    limits,
    setItems,
    setVote,
    userName,
    userSettingsByName,
    votesByHashRaw,
    setOffline,
    users,
    theme = 'light',
  } = props;

  setOffline(userName);
  const votesByHash = votesByHashRaw || {};
  const mytotal = getMyTotal({ votesByHash, userName });
  const total = getTotal({ votesByHash });
  const items = Object.values(itemsByHash || {});
  const animatedOutEffect = limits.animatedOutEffect;

  const extraProps = {
    itemsByHash,
    limits,
    mytotal,
    setItems,
    setVote,
    userName,
    userSettingsByName,
    votesByHash,
    theme,
  };

  if (limits.states[limits.state] === 'show votes') {
    return (
      <>
        <Navbar
          userName={userName}
          userSettingsByName={userSettingsByName}
          theme={theme}
          limits={limits}
          mytotal={mytotal}
        />
        <Container limits={limits} theme={theme}>
          <Row>
            <Section
              posts={items}
              section={5}
              emoji={
                <span role="img" aria-label="thinking">
                  🙄
                </span>
              }
              title=" Let's pick subjects to discuss!"
              extraProps={extraProps}
            />
          </Row>
        </Container>
        <Footer navbarHeight={navbarHeight} total={total} users={users} limits={limits} />
      </>
    );
  }

  return (
    <>
      <Navbar
        userName={userName}
        userSettingsByName={userSettingsByName}
        theme={theme}
        limits={limits}
        mytotal={mytotal}
      />
      <Container limits={limits} theme={theme}>
        <Row>
          <Section
            posts={items}
            section={1}
            emoji={
              <span role="img" aria-label="rainbow">
                🌈
              </span>
            }
            title="What went great?"
            extraProps={extraProps}
          />
          <Section
            posts={items}
            section={2}
            emoji={
              <span role="img" aria-label="fire">
                🔥
              </span>
            }
            title="What Drive Us?"
            extraProps={extraProps}
          />
        </Row>
        <Row>
          <Section
            posts={items}
            section={3}
            emoji={
              <span role="img" aria-label="sick">
                😷
              </span>
            }
            title="What went wrong?"
            extraProps={extraProps}
          />
          <Section
            posts={items}
            section={4}
            emoji={
              <span role="img" aria-label="thinking">
                🤔
              </span>
            }
            title=" What could go wrong?"
            extraProps={extraProps}
          />
        </Row>
      </Container>
      <Footer navbarHeight={navbarHeight} total={total} users={users} limits={limits} />
    </>
  );
};

function getMyTotal({ votesByHash, userName }) {
  return Object.values(votesByHash).reduce((sum, a) => {
    return (
      sum +
      a
        .join(':')
        .split(':')
        .reduce((n, x) => n + (x === userName), 0)
    );
  }, 0);
}

function getTotal({ votesByHash }) {
  return Object.values(votesByHash).reduce((sum, a) => sum + a.length, 0);
}

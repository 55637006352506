import React, { useState } from 'react';

import { getRandomColor } from 'services/color';
import logo from './logo.png';
import './Lobby.css';

// Lobby.component
export default (props) => {
  const { limits = {}, userSettingsByName, setUserSettings, children } = props;
  const users = Object.values(userSettingsByName || {}).filter((user) => user.online);
  const [userName, setUser] = useState();

  if (!userName) {
    const today = new Date();
    const daysLeft = 25 - today.getDate();
    return (
      <div>
        <div
          style={{
            boxShadow: '0 10px 30px 0 #CCD5DC',
            borderRadius: '5px',
            margin: '50px auto',
            width: '400px',
            padding: '20px',
            background: 'white',
          }}
        >
          <div style={{ display: 'flex' }}>
            <div>
              <h2 style={{ margin: 0, padding: 0 }}>CS's retro</h2>
              <h2>
                <small> - sprint: {limits.sprint}</small>
              </h2>
              {daysLeft > 0 && (
                <h2>
                  <small> - X-Mas: {daysLeft} days left!</small>
                </h2>
              )}
            </div>
          </div>
          <label>Write your username:</label>
          <input
            disabled={!(limits || {}).allowLogin}
            onKeyUp={handleUser(setUser, setUserSettings, userSettingsByName)}
            placeholder="press Enter to save"
            className="form-control"
            autoFocus={!(limits || {}).allowLogin}
          />
          <br />
          <hr />
          <code>
            {' '}
            {users.length > 1 && <p>There are {users.length} online users</p>}
            {users.length === 1 && <p>There is {users.length} online user</p>}
          </code>
        </div>
      </div>
    );
  }

  if (!(limits || {}).allowLogin) {
    // logout right away
    setUser();
  }

  // everything is ok!
  return children({
    limits,
    setUserSettings,
    userName,
    users,
    userSettingsByName,
  });
};

function handleUser(setUser, setUserSettings, userSettingsByName) {
  return (e) => {
    if (e.keyCode !== 13) return;
    const name = e.target.value;
    setUser(name);
    setUserSettings({
      ...userSettingsByName,
      [name]: {
        ...userSettingsByName[name],
        name,
        online: true,
        ...getRandomColor(),
      },
    });
  };
}

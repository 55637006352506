import styled from 'styled-components';

export const navbarHeight = 63;

export const Container = styled.div`
  background: ${({ theme } = {}) => (theme === 'dark' ? '#121212' : '#efefef')};
  height: calc(100vh - ${2 * navbarHeight}px);
  width: 100wh;
  margin-top: ${navbarHeight}px;
`;

export const Box = styled.div`
  height: calc(50vh - ${navbarHeight}px);
  width: 50vw;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
`;
export const Row = styled.div`
  display: flex;
`;
export const Title = styled.div`
  background: #495057;
  color: white;
  text-align: center;
  font-weight: bold;
`;
export const Input = styled.input`
  width: 100%;
  margin-bottom: 4px;
`;

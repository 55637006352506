import { connect } from 'react-firebase';
import Board from './Board.component';

const mapFirestoreToProps = ({ project = 'ffyn', userName = '' }, ref) => ({
  theme: `${project}/userSettings/${userName}/theme`,
  //-
  votesByHashRaw: `${project}/votes`,
  setVote: (data) => ref(`${project}/votes`).set(data),
  //-
  itemsByHash: `${project}/items`,
  setItems: (data) => ref(`${project}/items`).set(data),
  //-
  setOffline: (username) => ref(`${project}/userSettings/${username}/online`).onDisconnect().set(false),
});

export default connect(mapFirestoreToProps)(Board);

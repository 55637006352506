import React from 'react';

const Footer = (props) => {
  const { navbarHeight, total, users, limits } = props;

  return (
    <footer className="footer mt-auto py-3">
      <span className="text-muted" style={{ padding: '0 10px', height: `${navbarHeight}px` }}>
        <code>
          total_votes: {total} / {users.length * limits.votes} | users:{' '}
          {users.map((user) => (
            <span key={user.name} style={{ borderBottom: `5px solid ${user.background}`, marginRight: '10px' }}>
              {user.name}
            </span>
          ))}
        </code>
      </span>
    </footer>
  );
};

export default Footer;

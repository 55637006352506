export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let background = '#';
  for (var i = 0; i < 6; i++) {
    background += letters[Math.floor(Math.random() * 16)];
  }

  const r = parseInt(background.substring(1, 3), 16); // hexToR
  const g = parseInt(background.substring(3, 5), 16); // hexToG
  const b = parseInt(background.substring(5, 7), 16); // hexToB
  const color = r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000' : '#fff';

  return { color, background };
};

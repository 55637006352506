import React from 'react';

import ShowIf from 'components/ShowIf';
import { StyledPostIt } from './PostIt.style';

// PostIt.component
export default (props) => {
  const {
    author,
    description,
    hash,
    hidden,
    itemsByHash,
    limits,
    mytotal,
    section,
    setItems,
    setVote,
    userName,
    userSettingsByName,
    votesByHash,
    theme,
  } = props;

  const voteCount = limits.areVotesVisible ? ((votesByHash || {})[hash] || []).length : '+';
  const { background, color } = userSettingsByName[author] || {};
  const votesRaw = votesByHash[hash] || [];
  const usersThatVoted = [...new Set(votesRaw.map((v) => v.split(':')[0]))];
  const flag = usersThatVoted.indexOf(userName) === -1 || limits.allowMultiVote;
  const showClass = !hidden && limits.state === 0 ? 'animate__flash' : '';
  const currentState = limits.states[limits.state];

  const style = { background, color };
  if (theme === 'dark') {
    style.background = '#121212';
    style.color = 'white';
    style.borderLeft = `10px solid ${background}`;
  }

  return (
    <StyledPostIt className={`animate__animated ${showClass}`} theme={theme} style={style}>
      <span style={{ flex: 1, minHeight: '31px' }}>
        <span className="badge badge-secondary">{voteCount}</span>{' '}
        <span>
          <b>{author}</b>
        </span>
        : {hidden && author !== userName ? '***' : description}
      </span>
      <ShowIf value={hidden && author === userName}>
        <button className="btn btn-sm btn-success" onClick={handleShow({ setItems, itemsByHash, hash })}>
          show
        </button>
      </ShowIf>
      <ShowIf value={section > 2 && limits.isVotingTime && flag && limits.state === 2}>
        <span>
          <button
            style={{ marginLeft: '10px' }}
            onClick={handleVote({ setVote, userName, votesByHash, hash, limits, mytotal })}
            className="btn btn-sm btn-primary"
          >
            vote
          </button>
        </span>
      </ShowIf>
      <ShowIf value={!hidden && userName === limits.admin && currentState === 'remove tickets'}>
        <button
          className="btn btn-sm btn-danger"
          style={{ marginLeft: '10px' }}
          onClick={handleRemove({ setItems, itemsByHash, hash })}
        >
          remove
        </button>
      </ShowIf>
    </StyledPostIt>
  );
};

function handleShow({ setItems, itemsByHash, hash }) {
  return () => {
    setItems({
      ...itemsByHash,
      [hash]: {
        ...itemsByHash[hash],
        hidden: false,
      },
    });
  };
}

function handleRemove({ setItems, itemsByHash, hash }) {
  return () => {
    delete itemsByHash[hash];
    setItems({
      ...itemsByHash,
    });
  };
}

function handleVote({ setVote, userName, votesByHash, hash, limits, mytotal }) {
  return () => {
    if (!limits || mytotal >= limits.votes) {
      alert('No more votes for you!');
      return;
    }
    setVote({
      ...votesByHash,
      [hash]: !!votesByHash[hash]
        ? [...new Set([...votesByHash[hash], userName + ':' + mytotal])]
        : [userName + ':' + mytotal],
    });
  };
}

import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyAmjJ48QmpEX48jOCN6vmLX47ER8oeXyNg',
  authDomain: 'realtime-c4aa1.firebaseapp.com',
  databaseURL: 'https://realtime-c4aa1.firebaseio.com',
  projectId: 'realtime-c4aa1',
  storageBucket: 'realtime-c4aa1.appspot.com',
  messagingSenderId: 746626668695,
  appId: '1:746626668695:web:19c4408ce4f6683d',
};

export const setupFirebase = () => firebase.initializeApp(config);
